<template>
  <section />
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { mapActions, mapGetters } from 'vuex';
import UserAPI from '../api/modules/User';

export default {
  name: 'LoginCallback',
  created() {
    this.setAppLoadingState(true);
    const oAuthOptions = {};
    if (typeof this.$route.query.error !== 'undefined') {
      oAuthOptions.error = this.$route.query.error;
    }
    if (typeof this.$route.query.code !== 'undefined') {
      oAuthOptions.code = this.$route.query.code;
    }
    if (typeof this.$route.query.state !== 'undefined') {
      oAuthOptions.state = this.$route.query.state;
    }
    UserAPI.login(oAuthOptions).then((res) => {
      console.log({res});
      if (res.data.userProfile) {
        const userDetails = {
          first_name: res.data.userProfile.firstName,
          last_name: res.data.userProfile.lastName,
          name: res.data.userProfile.name,
          email: res.data.userProfile.email,
          image: res.data.userProfile.image,
          id_token: res.data.userProfile.idToken,
        }
        this.setUser(userDetails);
        this.setUserImage(userDetails.image);
        this.getProfile(true).then(() => this.navigateTo(this.getNextUrl() || 'Team'));
      }
    }).catch((err) => {
      console.log({err});
      // Re-route to Login page in case of errors
      this.setAppLoadingState(false);
      this.$buefy.toast.open({
        message: err?.response?.data?.message || 'Login failed. Please try again',
        type: 'is-danger',
        duration: 3000
      });
      setTimeout(() => this.$router.push({name: 'Login'}), 3000);
    });
  },
  methods: {
    navigateTo(routeName) {
      console.log({text: 'navigateTo', routeName});
      if (this.getAllFromState.length === 0) {
        this.pullAllConnectors().then(() => {
          this.routerPush(routeName);
        });
      } else {
        this.routerPush(routeName);
      }
    },
    routerPush(name) {
      this.$router.push({ name });
    },
    ...mapGetters('app', ['getNextUrl']),
    ...mapActions('user', ['getProfile', 'setUser', 'setUserImage']),
    ...mapActions('connectors', ['pullAllConnectors']),
    ...mapGetters('connectors', ['getAllFromState']),
  }
};
</script>
